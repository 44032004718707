import { template as template_8e058204e78d42a6a0c95d6eb35d6e95 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8e058204e78d42a6a0c95d6eb35d6e95(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
