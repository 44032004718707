import { template as template_c980c23ce7b24d1da36083a18e5d7fc7 } from "@ember/template-compiler";
const WelcomeHeader = template_c980c23ce7b24d1da36083a18e5d7fc7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
